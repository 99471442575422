import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// Patterns
const PAT_NAME = "^[ a-zA-Z ]{2,20}$";
const PAT_EMAIL = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
const PAT_CONTACT = "^[0-9]{10}$"

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  contactForm!: UntypedFormGroup;
  submitted = false;

  mailUrl = environment.mailUrl;
  baseUrl = environment.baseUrl;
  ccEmail = environment.ccEmail;

  status: string | undefined;

  constructor(private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    // private ngxSpinner: NgxSpinnerService,
    private router: Router,
    private http: HttpClient,
    private meta: Meta, private title: Title) {

    // SEO Optimisation
    this.title.setTitle('Contact');
    this.meta.updateTag({ name: 'description', content: 'Contact us to know more about Yoga by Vishwas' });
    this.meta.updateTag({
      name: 'keywords', content: 'contact, contact yoga by vishwas, email yoga by vishwas, call yoga by vishwas, vist yoga by vishwas'
    });

    this.route.queryParams.subscribe(params => {
      this.status = params['status'];
    });
    this.createForm();
  }
  createForm() {
    this.submitted = false;
    // Enguiry form validation
    this.contactForm = this.formBuilder.group(
      {
        // User personal
        name: ['', [Validators.required, Validators.pattern(PAT_NAME)]],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.required, Validators.pattern(PAT_CONTACT)]],
        message: ['', [Validators.required]],
        _template: ['table'],
        _captcha: [false]
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.contactForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      console.log(this.contactForm);
      return;
    } else {
      // this.ngxSpinner.show();
      this.contactForm.value._subject = '[' + this.contactForm.value.name
        + '] Potential client via YBV Contact Form';
      this.http.post(this.mailUrl,
        this.contactForm.value, { responseType: 'text' })
        .subscribe(
          {
            next: data => {
              this.status = "thanks";
              // this.ngxSpinner.hide();
              this.router.navigate(['/contact'], { queryParams: { status: 'thanks' } })
            },
            error: error => {
              this.status = "error";
              // this.ngxSpinner.hide();
              console.error('There was an error!', error);
            }
          });
    }
  }

  banner = {
    image: "/assets/images/common/hero-bg-2.webp",
    title: "Contact Us",
    description: "Contact us today",
    page: "Contact Us"
  }

  footerData = {
    backExists: "0",
    wallpaper: ""
  }
}
