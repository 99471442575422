<div id="rev_slider_26_1_wrapper" class="rev_slider_wrapper fullscreen-container home-rev-slider"
    data-alias="mask-showcase" data-source="gallery">
    <!-- START REVOLUTION SLIDER 5.4.1 fullscreen mode -->
    <div id="rev_slider_26_1" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.1">
        <ul>
            <!-- SLIDE 1 -->
            <li data-index="rs-72" data-transition="fade" data-slotamount="default" data-hideafterloop="0"
                data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300"
                data-thumb="/assets/images/home/banner/1/b1.webp" data-rotate="0" data-saveperformance="off"
                data-title="" data-param1="1" data-param2="" data-param3="" data-param4="" data-param5="" data-param6=""
                data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                <!-- MAIN IMAGE -->

                <img src="/assets/images/home/banner/1/b1.webp" alt="" data-bgcolor='#f8f8f8' alt=""
                    data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off"
                    class="rev-slidebg" data-no-retina style="transform: rotateY(180deg);">

                <!-- LAYER 1  right image overlay dark-->
                <!-- <div class="tp-caption tp-shape tp-shapewrapper  rs-parallaxlevel-tobggroup" 
                id="slide-75-layer-1" 
                data-x="['right','right','right','center']" data-hoffset="['0','0','0','0']" 
                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                data-fontweight="['100','100','400','400']"
                data-width="['full','full','full','full']"
                data-height="['full','full','full','full']"
                data-whitespace="nowrap"
     
                data-type="shape" 
                data-basealign="slide" 
                data-responsive_offset="off" 
                data-responsive="off"
                data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":150,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power2.easeInOut"}]'
                data-textAlign="['left','left','left','left']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
    
                style="z-index: 6;background-color:rgba(0,0,0,0.7);"> 
                </div> -->

                <!-- LAYER 3  Thin text title-->
                <!-- <div class="tp-caption   tp-resizeme slider-tag-line" 
                    id="slide-72-layer-3" 
                    data-x="['left','left','center','center']" data-hoffset="['50','50','0','0']" 
                    data-y="['top','top','top','top']" data-voffset="['150','150','150','100']" 
                    data-fontsize="['22',22','20','16']"
                    data-lineheight="['26','26','26','22']"
                    data-width="['700','600','600','380']"
                    data-height="none"
                    data-whitespace="nowrap"
         
                    data-type="text" 
                    data-responsive_offset="on" 

                    data-frames='[{"delay":300,"speed":750,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":500,"sfxcolor":"#ffffff","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
                    data-textAlign="['left','left','center','center']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"

                    style="z-index: 10; font-weight:500; letter-spacing:10px; color: #fff;font-family: 'Poppins', sans-serif; text-transform:uppercase">Variety</div> -->

                <!-- LAYER 4  Bold Title-->
                <!-- <div class="tp-caption   tp-resizeme" 
                    id="slide-72-layer-4" 
                    data-x="['left','left','center','center']" data-hoffset="['46','46','0','0']" 
                    data-y="['top','top','top','top']" data-voffset="['190','190','190','150']" 
                    data-fontsize="['64','54','44','34']"
                    data-lineheight="['80','70','60','50']"
                    data-width="['700','600','500','380']"
                    data-height="none"
                    data-whitespace="normal"
         
                    data-type="text" 
                    data-responsive_offset="on" 

                    data-frames='[{"delay":200,"speed":750,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":500,"sfxcolor":"#ffffff","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
                    data-textAlign="['left','left','center','center']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"

                    style="z-index: 10;white-space: normal;font-weight: 800; color: #fff;font-family: 'Poppins', sans-serif;">Flooring for Any Interior site</div> -->

                <!-- LAYER 5  Paragraph-->
                <!-- data-y="['middle','middle','middle','middle']" data-voffset="['30','60','50','70']" -->
                <!-- <div class="tp-caption   tp-resizeme" 
                    id="slide-72-layer-5" 
                    data-x="['right','right','center','center']" data-hoffset="['50','50','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['120','140','140','150']" 
                    data-fontsize="['18','18','18','16']"
                    data-lineheight="['30','30','30','30']"
                    data-width="['600','600','500','380']"
                    data-height="none"
                    data-whitespace="normal"
         
                    data-type="text" 
                    data-responsive_offset="on" 

                    data-frames='[{"delay":200,"speed":750,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":500,"sfxcolor":"#ffffff","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
                    data-textAlign="['left','left','center','center']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"

                        style="z-index: 10; white-space: normal; color: #fff; font-family: 'Poppins', sans-serif;">Inhale the future, exhale the past!</div>  -->

                <!-- LAYER 6  Read More-->
                <!-- <div class="tp-caption rev-btn  tp-resizeme" 
                    id="slide-72-layer-6" 
                    data-x="['left','left','center','center']" data-hoffset="['50','50','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['120','140','140','150']" 
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"                     
                    data-type="button" 
                    data-responsive_offset="on" 

                    data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;",
                    "speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]'
                    
                    data-textAlign="['left','left','center','center']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"

                    style="z-index:9; line-height:30px;"><a routerLink="/about/about1" class="site-button btn-half"><span> Read More</span></a></div>   -->

                <!-- Border left Part -->
                <!-- <div class="tp-caption tp-shape tp-shapewrapper " 
                    id="slide-72-layer-8" 
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                    data-width="full"
                    data-height="full"
                    data-whitespace="nowrap"
                    data-visibility="['on','off','off','off']"
         
                    data-type="shape" 
                    data-basealign="slide" 
                    data-responsive_offset="off" 
                    data-responsive="off"
                    data-frames='[{"delay":50,"speed":100,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power3.easeIn"}]'
                    data-textAlign="['inherit','inherit','inherit','inherit']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
        
                    style="z-index:8;background-color:rgba(0, 0, 0, 0);border-left:40px solid #eef1f2;"> </div>  -->

                <!-- Border bottom Part -->
                <div class="tp-caption tp-shape tp-shapewrapper " id="slide-72-layer-7"
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
                    data-height="full" data-whitespace="nowrap" data-visibility="['on','on','off','off']"
                    data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                    data-frames='[{"delay":50,"speed":100,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power3.easeIn"}]'
                    data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                    style="z-index:8;background-color:rgba(0, 0, 0, 0);border-bottom:80px solid #eef1f2;"> </div>
            </li>

            <!-- SLIDE 2 -->
            <li data-index="rs-73" data-transition="fade" data-slotamount="default" data-hideafterloop="0"
                data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300"
                data-thumb="/assets/images/home/banner/2/b2.webp 190w" data-rotate="0" data-saveperformance="off"
                data-title="" data-param1="1" data-param2="" data-param3="" data-param4="" data-param5="" data-param6=""
                data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                <!-- MAIN IMAGE -->
                <img src="/assets/images/home/banner/2/b2.webp" data-bgcolor='#f8f8f8' alt=""
                    data-bgposition="bottom center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off"
                    class="rev-slidebg" data-no-retina>

                <!-- LAYER 1  right image overlay dark-->
                <!-- <div class="tp-caption tp-shape tp-shapewrapper  rs-parallaxlevel-tobggroup" 
                id="slide-73-layer-1" 
                data-x="['right','right','right','center']" data-hoffset="['0','0','0','0']" 
                data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                data-fontweight="['100','100','400','400']"
                data-width="['full','full','full','full']"
                data-height="['full','full','full','full']"
                data-whitespace="nowrap"
     
                data-type="shape" 
                data-basealign="slide" 
                data-responsive_offset="off" 
                data-responsive="off"
                data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":150,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power2.easeInOut"}]'
                data-textAlign="['left','left','left','left']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
    
                style="z-index: 6;background-color:rgba(0,0,0,0.7);"> 
                </div> -->

                <!-- LAYER 3  Thin text title-->
                <!-- <div class="tp-caption   tp-resizeme slider-tag-line" 
                    id="slide-73-layer-3" 
                    data-x="['left','left','center','center']" data-hoffset="['50','50','0','0']" 
                    data-y="['top','top','top','top']" data-voffset="['150','150','150','100']" 
                    data-fontsize="['22',22','20','16']"
                    data-lineheight="['26','26','26','22']"
                    data-width="['700','600','600','380']"
                    data-height="none"
                    data-whitespace="nowrap"
         
                    data-type="text" 
                    data-responsive_offset="on" 

                    data-frames='[{"delay":300,"speed":750,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":500,"sfxcolor":"#ffffff","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
                    data-textAlign="['left','left','center','center']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"

                    style="z-index: 10; font-weight:500; letter-spacing:10px; color: #fff;font-family: 'Poppins', sans-serif; text-transform:uppercase">Modish</div> -->

                <!-- LAYER 4  Bold Title-->
                <!-- <div class="tp-caption   tp-resizeme" 
                    id="slide-73-layer-4" 
                    data-x="['left','left','center','center']" data-hoffset="['46','46','0','0']" 
                    data-y="['top','top','top','top']" data-voffset="['190','190','190','150']" 
                    data-fontsize="['64','54','44','34']"
                    data-lineheight="['80','70','60','50']"
                    data-width="['700','600','500','380']"
                    data-height="none"
                    data-whitespace="normal"
         
                    data-type="text" 
                    data-responsive_offset="on" 

                    data-frames='[{"delay":200,"speed":750,"sfxcolor":"#fff","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":500,"sfxcolor":"#ffffff","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
                    data-textAlign="['left','left','center','center']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"

                    style="z-index: 10;white-space: normal;font-weight: 800; color: #fff;font-family: 'Poppins', sans-serif;">Highest Quality Kitchen Solutions</div> -->

                <!-- LAYER 5  Paragraph-->
                <!-- data-y="['middle','middle','middle','middle']" data-voffset="['30','60','50','70']" -->
                <!-- <div class="tp-caption   tp-resizeme" 
                    id="slide-73-layer-5" 
                    data-x="['right','right','center','center']" data-hoffset="['50','50','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['120','140','140','150']" 
                    data-fontsize="['18','18','18','16']"
                    data-lineheight="['30','30','30','30']"
                    data-width="['600','600','500','380']"
                    data-height="none"
                    data-whitespace="normal"
         
                    data-type="text" 
                    data-responsive_offset="on" 

                    data-frames='[{"delay":200,"speed":750,"sfxcolor":"#000","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":500,"sfxcolor":"#000000","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
                    data-textAlign="['left','left','center','center']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"

                        style="z-index: 10; white-space: normal; color: #000; font-family: 'Poppins', sans-serif;">Yoga is joy of Being Yourself.</div>  -->

                <!-- LAYER 6  Read More-->
                <!-- <div class="tp-caption rev-btn  tp-resizeme" 
                    id="slide-73-layer-6" 
                    data-x="['left','left','center','center']" data-hoffset="['50','50','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['120','140','140','150']" 
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"                     
                    data-type="button" 
                    data-responsive_offset="on" 

                    data-frames='[{"from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;",
                    "speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},
                    {"delay":"wait","speed":500,"to":"y:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]'
                    
                    data-textAlign="['left','left','center','center']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"

                    style="z-index:9; line-height:30px;"><a routerLink="/about/about1" class="site-button btn-half"><span> Read More</span></a></div>   -->

                <!-- Border left Part -->
                <!-- <div class="tp-caption tp-shape tp-shapewrapper " 
                    id="slide-73-layer-8" 
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                    data-width="full"
                    data-height="full"
                    data-whitespace="nowrap"
                    data-visibility="['on','off','off','off']"
         
                    data-type="shape" 
                    data-basealign="slide" 
                    data-responsive_offset="off" 
                    data-responsive="off"
                    data-frames='[{"delay":50,"speed":100,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power3.easeIn"}]'
                    data-textAlign="['inherit','inherit','inherit','inherit']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
        
                    style="z-index:8;background-color:rgba(0, 0, 0, 0);border-left:40px solid #eef1f2;"> </div>  -->

                <!-- Border bottom Part -->
                <div class="tp-caption tp-shape tp-shapewrapper " id="slide-73-layer-7"
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
                    data-height="full" data-whitespace="nowrap" data-visibility="['on','on','off','off']"
                    data-type="shape" data-basealign="slide" data-responsive_offset="off" data-responsive="off"
                    data-frames='[{"delay":50,"speed":100,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power3.easeIn"}]'
                    data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                    style="z-index:8;background-color:rgba(0, 0, 0, 0);border-bottom:80px solid #eef1f2;"> </div>
            </li>
        </ul>
        <div class="tp-bannertimer"></div>
        <!-- left side social bar-->
        <div class="slide-left-social">
            <ul class="clearfix">
                <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/yogabyvishwas/"
                        class="sx-title-swip" data-hover="Instagram">Instagram</a></li>
                <li><a target="_blank" rel="noreferrer"
                        href="https://www.facebook.com/people/Yogabyvishwas/100054365465074/" class="sx-title-swip"
                        data-hover="Facebook">Facebook</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/@yogabyvishwas3056"
                        class="sx-title-swip" data-hover="Youtube">Youtube</a></li>
            </ul>
        </div>

    </div>
</div>