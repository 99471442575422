<div class="page-wraper"> 
    
    <!-- CONTENT START -->
    <div class="page-content">
        <!-- INNER PAGE BANNER -->
        <app-section-page-banner [data]="banner"></app-section-page-banner>
        <!-- INNER PAGE BANNER END -->
        
        <!-- SECTION CONTENT START -->
        <div class="section-full p-tb80 column-grid-4 inner-page-padding">
            <div class="container">
                <!-- Filter Nav START -->
              <!-- <div class="filter-wrap p-b30 text-center">
                    <ul class="filter-navigation masonry-filter clearfix">
                        <li class="active"><a class="btn from-top" data-filter="*" href="javascript:void(0);" data-hover="All">All</a></li>
                        <li><a class=" btn from-top" data-filter=".yoga" href="javascript:void(0);">Yoga</a></li>
                    </ul>
                </div> -->
                <!-- Filter Nav END -->
                
                <!-- GALLERY CONTENT START -->
                 <!-- <ul  class="masonry-outer mfp-gallery row work-grid clearfix list-unstyled">
                 
                    <li *ngFor="let object of portfolios" class="masonry-item {{object.category}} col-xl-3  col-lg-4 col-md-6 col-sm-12 m-b30">
                        <app-elements-box12 [data]='object'></app-elements-box12>
                    </li>
                                                                                                  
                 </ul> -->
                <!-- GALLERY CONTENT END -->


                <!-- GALLERY CONTENT START -->
                <!-- <ul class="masonry-outer mfp-gallery work-grid row clearfix list-unstyled">
                
                    <li *ngFor="let object of portfolios"
                        class="masonry-item {{object.category}} col-xl-3  col-lg-4 col-md-6 col-sm-12 m-b30">
                        <app-elements-box5 [data]='object'></app-elements-box5>
                    </li>
                
                </ul> -->
                <!-- GALLERY CONTENT END -->

                <!-- GALLERY CONTENT START -->
                <ul id="load-more-item-2" class="masonry-outer mfp-gallery work-grid row clearfix list-unstyled">
                    <!-- COLUMNS 1 -->
                    <li *ngFor="let object of portfolios"
                        class="masonry-item {{object.category}} col-xl-3 col-lg-4  col-md-6 col-sm-12 m-b30">
                        <app-elements-box6 [data]='object' gap='0'></app-elements-box6>
                    </li>
                </ul>
                <!-- GALLERY CONTENT END -->
                
                <div class="text-center load-more-btn-outer" style="background-image:url(assets/images/background/cross-line.png)">
                    <a target="_blank" href="https://www.instagram.com/yogabyvishwas/" class="site-button-secondry btn-half"><span>Open Instagram</span></a>
                </div>
            </div>

        
        </div>
        <!-- SECTION CONTENT END  -->

    </div>
    <!-- CONTENT END -->

</div>