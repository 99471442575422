import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-testimonials',
  templateUrl: './section-testimonials.component.html',
  styleUrls: ['./section-testimonials.component.css']
})
export class SectionTestimonialsComponent implements OnInit {

  data:any = {
    experience: {
      title: "Experience",
      items: [
        {
          count: "15",
          title: "Years of Experience"
        },
        // {
        //   count: "340",
        //   title: "Project Taken"
        // },
        // {
        //   count: "86",
        //   title: "Awards Won"
        // },
        // {
        //   count: "6081",
        //   title: "Instagram Followers"
        // }
      ]
    },
    testimonials: {
      title: "Testimonial",
      content: [
        {
          name: "Cheryl Ling",
          quote: "“It was a very pleasant photoshooting experience with Vishwas. We met to discuss the details of the shoot before the actual shooting day. He understands what his clients are asking for. Being a local himself, he is familiar with the best places to shoot around Mysore. He is an attentive and a passionate photographer who will definitely meets your expectation. Will definitely engage him as my photographer in the coming future! Loving all my pictures taken by him, which have received many compliments from my friends.”",
          link: "https://g.co/kgs/LzfwpE"
        },
        {
          name: "IngerYoga",
          quote: "“The photoshoot with Vishwas was great. It was efficient, with high quality photos. Photoshoot had a great price and photographer gives you all the photos he took. Vishwas is a really nice guy and you will surely enjoy photoshooting with him. I reccomend it.”",
          link: "https://g.co/kgs/QxixAs"
        }


      ]
    }
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
