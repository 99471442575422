<div class="section-full container-fluid">

    <div class="section-content">
        <div class="row">

            <div class="col-xl-6 col-lg-6 col-md-12 bg-gray bg-cover bg-no-repeat"
                style="background-image:url(/assets/portfolio/2.webp)">
                <div class="sx-left-part mobile-page-padding">
                    <!-- TITLE START -->
                    <div class="section-head">
                        <div class="sx-separator-outer separator-left">
                            <div class="sx-separator bg-white bg-moving bg-repeat-x"
                                style="background-image:url(assets/images/background/cross-line2.png)">
                                <h3 class="sep-line-one">{{data.experience.title}}</h3>
                            </div>
                        </div>
                    </div>
                    <!-- TITLE END -->
                    <div class="counter-blocks">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30"
                                *ngFor="let object of data.experience.items">
                                <div class="sx-count  text-black sx-icon-box-wraper bg-repeat  bg-white p-a30"
                                    style="background-image:url(assets/images/background/bg-5.png)">
                                    <h2 class="st-count-number sx-text-primary text-left"><span
                                            class="counter">{{object.count}}</span></h2>
                                    <h4 class="m-tb0">{{object.title}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-12 bg-white bg-repeat"
                style="background-image:url(assets/images/background/bg-12.jpg)">

                <div class="sx-right-part mobile-page-padding  p-t80 p-b50">
                    <!-- TITLE START -->
                    <div class="section-head">
                        <div class="sx-separator-outer separator-left">
                            <div class="sx-separator bg-white bg-moving bg-repeat-x"
                                style="background-image:url(assets/images/background/cross-line2.png)">
                                <h3 class="sep-line-one">{{data.testimonials.title}}</h3>
                            </div>
                        </div>
                    </div>
                    <!-- TITLE END -->

                    <!-- TESTIMONIAL START -->
                    <div class="owl-carousel testimonial-home number-slider">
                        <div class="item" *ngFor="let object of data.testimonials.content">
                            <div class="testimonial-2  hover-animation-1">
                                <div class="testimonial-detail clearfix">
                                    <!-- <div class="testimonial-pic shadow scale-in-center">
                                        <img style="border-radius: 50px; background-color: transparent;" src="{{object.image}}" width="100" height="100" alt="">
                                    </div> -->
                                    <h4 class="testimonial-name">{{object.name}}</h4>
                                    <a target="_blank" href="{{object.link}}"><img style="width:70px"
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1200px-Google_2015_logo.svg.png"></a>
                                    <span class="testimonial-position">{{object.role}}</span>
                                    <span class="fa fa-quote-right"></span>
                                </div>
                                <div class="testimonial-text bg-white  shadow-sm">

                                    <p>{{object.quote}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


        </div>
    </div>

</div>

<div class="bg-secondary mobile-page-padding p-b50 p-t80 section-full">
    <div class="container">
        <div class="section-content">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12"></div>
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="video-section-full-v2">
                        <div class="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                            style="background-image: url(https://i3.ytimg.com/vi/p0kAK5mR9JU/hqdefault.jpg);">
                            <div class="overlay-main bg-black opacity-04"></div>
                            <div class="video-section-inner">
                                <div class="video-section-content"><a class="mfp-video play-now"
                                        href="https://www.youtube-nocookie.com/embed/p0kAK5mR9JU?si=rqoux9YuyiWdMg-M">
                                        <!-- https://www.youtube.com/embed/p0kAK5mR9JU?si=rqoux9YuyiWdMg-M -->
                                        <i class="icon fa fa-play"></i><span class="ripple"></span></a>
                                    <div class="video-section-bottom">
                                        <h3 class="sx-title text-white">Journey<br>of Laruga</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>