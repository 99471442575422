<div class="site-header header-style-1 nav-wide">
    <div class="top-header bg-gray">
        <div class="mx-5">
            <div class="row">
                <div class="col-12">
                    <ul class="top-header-contact-info row">
                        <li class="col-sm-6 text-center text-sm-start col-12"><svg xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                                viewBox="0 0 75.294 75.294" xml:space="preserve"
                                style="enable-background: new 0 0 32 32; width: 20px; padding-right: 5px;">
                                <g>
                                    <path
                                        d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9   c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089   H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065   c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016   c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102   c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069   c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z">
                                    </path>
                                </g>
                            </svg><a href="mailto:hello@yogabyvishwas.com">hello@yogabyvishwas.com</a>
                        </li>
                        <li class="col-sm-6 text-center col-12"><svg xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px"
                                viewBox="0 0 512 512" xml:space="preserve"
                                style="enable-background: new 0 0 32 32; width: 20px; padding-right: 5px;">
                                <g>
                                    <g>
                                        <path
                                            d="M430.857,83.429H216V51.355c0-9.469-7.798-17.07-17.265-17.07H83.961c-9.467,0-17.675,7.601-17.675,17.07v33.81    C28.571,91.953,0,125.165,0,165.017v231.629c0,44.742,36.401,81.069,81.143,81.069h349.714c44.742,0,81.143-36.327,81.143-81.07    V165.017C512,120.274,475.599,83.429,430.857,83.429z M100.571,68.571h81.143v298.286h-81.143V68.571z M430.857,443.429H81.143    c-25.837,0-46.857-20.946-46.857-46.784V165.017c0-20.842,13.714-38.542,32-44.609v263.609c0,9.467,8.208,17.126,17.675,17.126    h114.773c9.467,0,17.265-7.658,17.265-17.126V117.714h214.857c25.837,0,46.857,21.465,46.857,47.303v231.627h0.001    C477.714,422.482,456.694,443.429,430.857,443.429z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M425.143,166.857H278.857c-9.467,0-17.143,7.675-17.143,17.143s7.675,17.143,17.143,17.143h146.286    c9.467,0,17.143-7.675,17.143-17.143S434.61,166.857,425.143,166.857z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M297.143,249.143h-18.286c-9.467,0-17.143,7.675-17.143,17.143c0,9.467,7.675,17.143,17.143,17.143h18.286    c9.467,0,17.143-7.675,17.143-17.143C314.286,256.818,306.61,249.143,297.143,249.143z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M361.143,249.143h-18.286c-9.467,0-17.143,7.675-17.143,17.143c0,9.467,7.675,17.143,17.143,17.143h18.286    c9.467,0,17.143-7.675,17.143-17.143C378.286,256.818,370.61,249.143,361.143,249.143z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M425.143,249.143h-18.286c-9.467,0-17.143,7.675-17.143,17.143c0,9.467,7.675,17.143,17.143,17.143h18.286    c9.467,0,17.143-7.675,17.143-17.143C442.286,256.818,434.61,249.143,425.143,249.143z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M297.143,298.286h-18.286c-9.467,0-17.143,7.675-17.143,17.143s7.675,17.143,17.143,17.143h18.286    c9.467,0,17.143-7.675,17.143-17.143S306.61,298.286,297.143,298.286z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M361.143,298.286h-18.286c-9.467,0-17.143,7.675-17.143,17.143s7.675,17.143,17.143,17.143h18.286    c9.467,0,17.143-7.675,17.143-17.143S370.61,298.286,361.143,298.286z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M425.143,298.286h-18.286c-9.467,0-17.143,7.675-17.143,17.143s7.675,17.143,17.143,17.143h18.286    c9.467,0,17.143-7.675,17.143-17.143S434.61,298.286,425.143,298.286z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M297.143,347.429h-18.286c-9.467,0-17.143,7.675-17.143,17.143c0,9.467,7.675,17.143,17.143,17.143h18.286    c9.467,0,17.143-7.675,17.143-17.143C314.286,355.104,306.61,347.429,297.143,347.429z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M361.143,347.429h-18.286c-9.467,0-17.143,7.675-17.143,17.143c0,9.467,7.675,17.143,17.143,17.143h18.286    c9.467,0,17.143-7.675,17.143-17.143C378.286,355.104,370.61,347.429,361.143,347.429z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M425.143,347.429h-18.286c-9.467,0-17.143,7.675-17.143,17.143c0,9.467,7.675,17.143,17.143,17.143h18.286    c9.467,0,17.143-7.675,17.143-17.143C442.286,355.104,434.61,347.429,425.143,347.429z">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                            <a href="tel:+919845598190">+91 98455 98190</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="sticky-header main-bar-wrapper navbar-expand-lg">
        <div class="main-bar">
            <div class="container clearfix">
                <div class="logo-header">
                    <div class="logo-header-inner logo-header-one">
                        <a routerLink="/index">
                            <!-- <span style="font-size: 16px; padding-right: 20px;">YOGA BY VISHWAS</span> -->
                            <img height="75px" style="padding: 15px 0; max-height: 70px;" src="/assets/images/logo.webp"
                                alt="Yoga By Vishwas" />
                        </a>
                    </div>
                </div>
                <button type="button" class="navbar-toggler" data-toggle="collapse" data-target=".navbar-collapse"
                    data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false"
                    aria-label="Toggle navigation" (click)="resetHamburguerIcon()">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar icon-bar-first"></span>
                    <span class="icon-bar icon-bar-two"></span>
                    <span class="icon-bar icon-bar-three"></span>
                </button>

                <div [ngbCollapse]="isCollapsed" id="navbarSupportedContent"
                    class="header-nav nav-light navbar-collapse collapse justify-content-end">
                    <ul class="nav navbar-nav">
                        <li><a routerLink="/" href="/" routerLinkActive="active" (click)="collapseNavbar()">Home</a>
                        </li>
                        <li><a routerLink="/portfolio" href="/portfolio" routerLinkActive="active"
                                (click)="collapseNavbar()">Portfolio</a>
                        </li>
                        <li><a routerLink="/contact" routerLinkActive="active" href="/contact"
                                (click)="collapseNavbar()">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div *ngIf="!production" class="env-div px-5 py-3 text-center" style="border-bottom: 2px solid white;">THIS
                IS A
                {{name}} ENVIRONMENT</div>
        </div>
    </div>
</div>