<div class="page-wraper">  
      	
    <!-- HEADER START -->
    <app-header4></app-header4>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-page-banner [data]="banner"></app-section-page-banner>
        <!-- INNER PAGE BANNER END -->
         
        <!-- SECTION CONTENTG START -->
        <div class="section-full p-tb80 inner-page-padding">
        
                    <div class="container">

                                <!-- TITLE START -->
                                <div class="section-head">
                                    <div class="sx-separator-outer separator-center">
                                        <div class="sx-separator bg-white bg-moving bg-repeat-x" style="background-image:url(images/background/cross-line2.png)">
                                            <h3 class="sep-line-one">Icon Fonts</h3>
                                        </div>
                                    </div>
                                </div>                   
                                <!-- TITLE END -->                  
                                <div class="section-content">
                                    <div class="sx-box">
                                        <div class="icon-font">
                                        
                                            <div class="icon-font-block" *ngFor="let object of fontIcons">
                                                <div class="flat-icon {{object}}"></div>
                                                <div class="class-name">.{{object}}</div>
                                            </div>        
                                            
                                        </div>
                                    </div>
                               </div>

                    </div>              
       </div>  
        <!-- SECTION CONTENT END -->
        
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer1 [data]='footerData'></app-footer1>
    <!-- FOOTER END -->
    
</div>