<div class="sx-box   image-hover-block">
    <div class="sx-thum-bx">
        <img src="{{data.image}}" alt="">
    </div>
    <div class="sx-info  p-t20 text-white">
        <h4 class="sx-tilte"><a routerLink="/projects/project-detail1">{{data.title}}</a></h4>
        <p class="m-b0">{{data.place}}</p>      
    </div>
    <a class="mfp-link" href="{{data.image}}">
        <i class="fa fa-arrows-alt"></i>
    </a>                                            
</div>