<div class="page-wraper">  
      	
    <!-- HEADER START -->
    <app-header4></app-header4>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-page-banner [data]="banner"></app-section-page-banner>
        <!-- INNER PAGE BANNER END -->
         
        <!-- SECTION CONTENTG START -->
        <div class="section-full p-t80 p-b80 inner-page-padding">
            <div class="container">
            
                <div class="faq-1">
                    <!-- TITLE START -->
                    <div class="section-head">
                        <div class="sx-separator-outer separator-left">
                            <div class="sx-separator bg-white bg-moving bg-repeat-x" style="background-image:url(images/background/cross-line2.png)">
                                <h3 class="sep-line-one">FAQ</h3>
                            </div>
                        </div>
                    </div>                                        
                    <!-- TITLE END -->                         
                    <div class="row">
                        <div class="col-md-6 faq-list">
                            <!-- Accordian -->
                            <div>
                                 <h4>How do we work?</h4>
                                <p>Progressively generate synergistic total linkage through cross media are intellectual capital. Enthusiastically parallel task team building e-tailers without standards compliant initiatives. Progressively monetize client cent outsourcing with excellent communities..</p> 
                            </div>
                            
                            <div>
                                <h4>How long will it take to build my project?</h4>
                                <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popular ised sheets containing Lorem Ipsum passagese.</p>
                            </div>
                            
                            <div>
                                <h4>What services do we offer?</h4>
                                <p>Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley.</p>
                            </div>                                                                
                            

                        </div>
                        <div class="col-md-6">
                            <!-- Accordian -->
                            <div class="sx-accordion acc-bg-gray" id="accordion5">
                            
                                <div class="panel sx-panel">
                                    <div class="acod-head acc-actives">
                                         <h4 class="acod-title">
                                            <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5" > 
                                                How do we work? 
                                                <span class="indicator"><i class="fa fa-plus"></i></span>
                                            </a>
                                         </h4>
                                    </div>
                                    <div id="collapseOne5" class="acod-body collapse show">
                                        <div class="acod-content p-a15">
                                            <p>
                                            Progressively generate synergistic total linkage through cross media are intellectual capital. Enthusiastically parallel task team building e-tailers without standards compliant initiatives. Progressively monetize client cent outsourcing with excellent communities.</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="panel sx-panel">
                                    <div class="acod-head">
                                         <h4 class="acod-title">
                                            <a data-toggle="collapse" href="#collapseTwo5" class="collapsed" data-parent="#accordion5"> 
                                             What services do we offer?
                                            <span class="indicator"><i class="fa fa-plus"></i></span>
                                            </a>
                                         </h4>
                                    </div>
                                    <div id="collapseTwo5" class="acod-body collapse">
                                        <div class="acod-content p-a15">
                                            <p>
                                            Progressively generate synergistic total linkage through cross media are intellectual capital. Enthusiastically parallel task team building e-tailers without standards compliant initiatives. Progressively monetize client cent outsourcing with excellent communities.</p></div>
                                    </div>
                                </div>
                                
                                <div class="panel sx-panel">
                                    <div class="acod-head">
                                        <h4 class="acod-title">
                                        <a data-toggle="collapse"  href="#collapseThree5" class="collapsed"  data-parent="#accordion5"> 
                                            Do you have a purchasing fee on the product?
                                        <span class="indicator"><i class="fa fa-plus"></i></span>
                                        </a>
                                     </h4>
                                    </div>
                                    <div id="collapseThree5" class="acod-body collapse">
                                        <div class="acod-content p-a15">
                                            <p>
                                            Progressively generate synergistic total linkage through cross media are intellectual capital. Enthusiastically parallel task team building e-tailers without standards compliant initiatives. Progressively monetize client cent outsourcing with excellent communities.</p></div>
                                    </div>
                                </div>
                                
                                <div class="panel sx-panel">
                                    <div class="acod-head">
                                         <h4 class="acod-title">
                                            <a data-toggle="collapse" href="#collapseFour5" class="collapsed" data-parent="#accordion5" > 
                                                How long will it take to to design and build my project?
                                                <span class="indicator"><i class="fa fa-plus"></i></span>
                                            </a>
                                         </h4>
                                    </div>
                                    <div id="collapseFour5" class="acod-body collapse">
                                        <div class="acod-content p-a15">
                                            <p>
                                            Progressively generate synergistic total linkage through cross media are intellectual capital. Enthusiastically parallel task team building e-tailers without standards compliant initiatives. Progressively monetize client cent outsourcing with excellent communities.</p></div>
                                    </div>
                                </div>
        
                                <div class="panel sx-panel">
                                    <div class="acod-head">
                                         <h4 class="acod-title">
                                            <a data-toggle="collapse" href="#collapseFive5" class="collapsed" data-parent="#accordion5" > 
                                                What should I have at our first meeting for my project? 
                                                <span class="indicator"><i class="fa fa-plus"></i></span>
                                            </a>
                                         </h4>
                                    </div>
                                    <div id="collapseFive5" class="acod-body collapse">
                                        <div class="acod-content p-a15">
                                            <p>
                                                Progressively generate synergistic total linkage through cross media are intellectual capital. Enthusiastically parallel task team building e-tailers without standards compliant initiatives. Progressively monetize client cent outsourcing with excellent communities.</p>
                                        </div>
                                    </div>
                                </div>
                                
                                                                    
                            </div>
                        </div>                            
                    </div>
                </div>

            </div>
        </div>
        <!-- SECTION CONTENT END -->
        
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer1 [data]='footerData'></app-footer1>
    <!-- FOOTER END -->
    
</div>