<hr class="p-0 m-0" style="border-top: 2px solid black;" />
<footer class="site-footer footer-large  footer-dark	footer-wide">

    <!-- FOOTER BLOCKES START -->
    <div class="footer-top overlay-wraper {{ data.backExists=='1' ? 'bg-cover' : '' }}"
        style="background-image:url('{{data.wallpaper}}')">
        <div class="overlay-main {{ data.backExists=='1' ? 'sx-bg-secondry opacity-08' : '' }}"></div>
        <div class="container">
            <div class="row">
                <!-- ABOUT COMPANY -->
                <div class="col-lg-4 col-md-6 col-sm-6 px-2">
                    <div class="widget widget_about">
                        <!--<h4 class="widget-title">About Company</h4>-->
                        <div class="logo-footer clearfix p-b15">
                            <a routerLink="/home">
                                <img src="/assets/images/logo.webp" alt="Yoga By Vishwas" />
                            </a>
                        </div>
                        <p>Highly reviewed professional yoga photographer around Bengaluru and Mysore.</p>

                        <ul class="social-icons sx-social-links d-flex justify-content-start">
                            <li class="pr-2"><a href="https://www.facebook.com/people/Yogabyvishwas/100054365465074/"
                                    class="fa fa-facebook"></a></li>
                            <li class="pr-2"><a href="https://www.instagram.com/yogabyvishwas/"
                                    class="fa fa-instagram"></a></li>
                            <li><a href="https://www.youtube.com/@yogabyvishwas3056" class="fa fa-youtube"></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-1 col-md-6 col-sm-6">
                </div>

                <!-- USEFUL LINKS -->
                <div class="col-lg-3 col-md-6 col-sm-6 footer-col-3 mt-5 px-2">
                    <div class="widget widget_services inline-links">
                        <h5 class="widget-title">Quick Links</h5>
                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/portfolio">Portfolio</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <!-- CONTACT US -->
                <div class="col-lg-4 col-md-6 col-sm-6 mt-5 px-2">
                    <div class="widget widget_address_outer">
                        <h5 class="widget-title">Contact Us</h5>
                        <ul class="widget_address">
                            <li>#201, 9th Cross, 2nd Stage, Kuvempu Nagara, Mysuru, Karnataka 570023, India</li>
                            <li>hello@yogabyvishwas.com</li>
                            <li>+91 98455 98190</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->
    <div class="footer-bottom overlay-wraper footer__copyrights--markdown py-2">
        <div class="overlay-main"></div>
        <div id="bottom-bar" class="bottom-bar">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-1">
                        <div #mlogo>
                            <ng-template>
                                {{ src }}
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-10 d-flex align-self-center justify-content-end">© Copyright {{currentYear}}.
                        <!-- <b>Yoga By Vishwas</b>. -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>