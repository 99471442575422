<div class="page-wraper"> 
       	

    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- SLIDER START -->
        <app-section-slider></app-section-slider>
        <!-- SLIDER END -->         

        <!-- ABOUT COMPANY START -->
        <!-- <app-section-about1 [data]='about'></app-section-about1> -->
        <!-- ABOUT COMPANY END -->
                    
        <!-- WHAT WE DO START -->
        <!-- <app-section-what-we-do1 [data]='whatwedo'></app-section-what-we-do1> -->
        <!-- WHAT WE DO END --> 

        <!-- TESTIMONIAL START -->
        <app-section-testimonials></app-section-testimonials>
        <!-- TESTIMONIAL  END -->
        
        <!-- OUR SERVICES START -->
        <app-section-services [data]='services'></app-section-services>
        <!-- OUR SERVICES  END -->
        
        <!-- PROJECTS START -->
        <!-- <app-section-projects1 [data]='projects'></app-section-projects1> -->
        <!-- PROJECTS  END -->            
                    
        <!-- OUR CLIENTS -->
        <!-- <app-section-clients1 [data]='clients'></app-section-clients1>    -->
        <!-- OUR CLIENTS END --> 
        
        <!-- OUR TEAM START -->
        <!-- <app-section-team1 [data]='team'></app-section-team1> -->
        <!-- OUR Team END -->            
                            
    </div>
    <!-- CONTENT END -->
          
</div>