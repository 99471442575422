<div class="page-wraper">

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-page-banner [data]="banner"></app-section-page-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SECTION CONTENTG START -->
        <div class="section-full p-tb80 inner-page-padding">
            <!-- LOCATION BLOCK-->
            <div class="container">

                <!-- GOOGLE MAP & CONTACT FORM -->
                <div class="section-content">
                    <!-- CONTACT FORM-->
                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12">

                            <form *ngIf="status!=='thanks'" id="contact-form" name="contact_form"
                                class="contact-form cons-contact-form bg-gray p-a30" method="post"
                                novalidate="novalidate" [formGroup]="contactForm">

                                <div class="row">
                                
                                    <div class="col-md-12 section-head">
                                        <div class="sx-separator-outer separator-left">
                                            <div class="sx-separator bg-white bg-moving bg-repeat-x"
                                                style="background-image:url(assets/images/background/cross-line2.png)">
                                                <h3 class="sep-line-one">Form</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 contact-one">
                                        <!-- <div class="input-box">
                                                                    <input type="text" name="form_name" value="" placeholder="First Name"
                                                                        required="" aria-required="true">
                                                                    <div class="icon">
                                                                        <i class="fa fa-user" aria-hidden="true"></i>
                                                                    </div>
                                                                </div> -->
                                        <div class="input-box form-group">
                                            <input [ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
                                                class="form-control" formControlName="name" id="name" name="name"
                                                placeholder="Name" type="text" />
                                            <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                                                <div *ngIf="f['name'].errors['required']">Name is required</div>
                                                <div *ngIf="f['name'].errors['pattern']">Enter a valid Name</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 contact-one">
                                        <div class="input-box form-group">
                                            <input [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                                                class="form-control" formControlName="email" id="email" name="email"
                                                placeholder="Email" type="text" />
                                            
                                            <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                                <div *ngIf="f['email'].errors['required']">Email is required</div>
                                                <div *ngIf="f['email'].errors['email']">Enter a valid email</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 contact-one">
                                        <div class="input-box form-group">
                                            <input [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }"
                                                class="form-control" formControlName="phone" id="phone" name="phone"
                                                placeholder="Phone" type="text" />
                                            
                                            <div *ngIf="submitted && f['phone'].errors" class="invalid-feedback">
                                                <div *ngIf="f['phone'].errors['required']">Phone is required</div>
                                                <div *ngIf="f['phone'].errors['pattern']">Enter a valid phone number</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 contact-one">
                                        <div class="input-box">
                                            <textarea [ngClass]="{ 'is-invalid': submitted && f['message'].errors }"
                                                name="message" formControlName="message" class="form-control"
                                                id="message" cols="30" rows="4" placeholder="Message"></textarea>
                                            <div *ngIf="submitted && f['message'].errors" class="invalid-feedback">
                                                <div *ngIf="f['message'].errors['required']">Message is required</div>
                                            </div>
                                            <div class="icon envelop">
                                                <!-- <i class="fa fa-comment" aria-hidden="true"></i> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 mt-5">
                                            <div class="text-right">
                                                <button name="submit" type="submit" value="Submit" (click)="onSubmit()" class="site-button btn-half"><span> Submit</span>
                                            
                                                </button>
                                            </div>
                                            <!-- <button class="site-button btn-half" type="submit"
                                                data-loading-text="Sending Message...">SUBMIT<span class="flaticon-next"></span></button> -->
                                        
                                    </div>
                                </div>
                            </form>
                            <div *ngIf="status==='thanks'" class="d-flex h-100" style="background-color: #eee;">
                                <div class="col-12 d-flex text-center justify-content-center align-self-center">
                                <h6>Thank you so much for contacting <b>Yoga By Vishwas</b>.<br />We would get back to
                                    you as soon
                                    as possible.</h6>
                            </div></div>


                            <!-- <form class="contact-form cons-contact-form bg-gray p-a30" method="post"
                                action="https://formsubmit.co/admin@yogabyvishwas.com">
                                <div class="contact-one">

                                    <div class="section-head">
                                        <div class="sx-separator-outer separator-left">
                                            <div class="sx-separator bg-white bg-moving bg-repeat-x"
                                                style="background-image:url(assets/images/background/cross-line2.png)">
                                                <h3 class="sep-line-one">Form</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <input name="username" type="text" required class="form-control"
                                            placeholder="Name">
                                    </div>

                                    <div class="form-group">
                                        <input name="email" type="text" class="form-control" required
                                            placeholder="Email">
                                    </div>

                                    <div class="form-group">
                                        <textarea name="message" rows="4" class="form-control " required
                                            placeholder="Message"></textarea>
                                    </div>

                                    <div class="text-right">
                                        <button name="submit" type="submit" value="Submit"
                                            class="site-button btn-half"><span> Submit</span>

                                        </button>
                                    </div>

                                </div>
                            </form> -->
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="contact-info block-shadow bg-white bg-center p-a40"
                                style="background-image:url(assets/images/background/bg-map.png)">

                                <div>
                                    <!-- TITLE START -->
                                    <div class="section-head">
                                        <div class="sx-separator-outer separator-left">
                                            <div class="sx-separator bg-white bg-moving bg-repeat-x"
                                                style="background-image:url(assets/images/background/cross-line2.png)">
                                                <h3 class="sep-line-one">Info</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TITLE END -->
                                    <div class="sx-icon-box-wraper left p-b30">
                                        <div class="icon-xs"><i class="fa fa-phone"></i></div>
                                        <div class="icon-content">
                                            <h5 class="m-t0">Phone number</h5>
                                            <p>+91 98455 98190</p>
                                        </div>
                                    </div>

                                    <div class="sx-icon-box-wraper left p-b30">
                                        <div class="icon-xs"><i class="fa fa-envelope"></i></div>
                                        <div class="icon-content">
                                            <h5 class="m-t0">Email address</h5>
                                            <p>hello@yogabyvishwas.com</p>
                                        </div>
                                    </div>

                                    <div class="sx-icon-box-wraper left">
                                        <div class="icon-xs"><i class="fa fa-map-marker"></i></div>
                                        <div class="icon-content">
                                            <h5 class="m-t0">Address info</h5>
                                            <p>#201, 9th Cross, 2nd Stage, Kuvempu Nagara, Mysuru, Karnataka 570023,
                                                India</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div class="gmap-outline">
            <!-- <div id="gmap_canvas2" class="google-map"></div> -->
            <!-- <google-map  height="500px" width="100%"></google-map> -->
            <iframe _ngcontent-foj-c23="" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" loading="lazy"
                src="https://maps.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15594.155194016606!2d76.62692!3d12.2794589!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x40978851287aae42!2sVishwas%20Photography!5e0!3m2!1sen!2suk!4v1668859311673!5m2!1sen!2suk&amp;t=m&amp;z=16&amp;output=embed&amp;iwloc=near"
                title="%3$s" aria-label="%3$s" style="width: 100%; min-height: 600px;"></iframe>
        </div>
        <!-- SECTION CONTENT END -->

    </div>
    <!-- CONTENT END -->

</div>