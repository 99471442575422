import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeModule } from './modules/home/home.module';
import { PageError404Component } from './pages/other/page-error404/page-error404.component';
import { PrivacyComponent } from './pages/other/privacy/privacy.component';
import { TermsComponent } from './pages/other/terms/terms.component';
import { SectionTestimonialsComponent } from './sections/home/section-testimonials/section-testimonials.component';

const routes: Routes = [
  { path: '', loadChildren: () => HomeModule },
  { path: 'portfolio', loadChildren: () => import('./modules/portfolio/portfolio.module').then(m => m.PortfolioModule) },
  { path: 'contact', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
  // { path: 'other', loadChildren: () => import('./modules/other/other.module').then(m => m.OtherModule) },
  { path: 'testimonial', component: SectionTestimonialsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: '404', component: PageError404Component },
  { path: '**/**', component: PageError404Component },
  { path: '**', pathMatch: 'full', component: PageError404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
