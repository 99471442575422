<div id="wrapper">
    <div class="error-block section-space">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="error-top-wrapper">
                        <div class="error-top-item">
                            <img src="/assets/images/other/page-not-found/1.png" class="img-responsive" alt="404">
                        </div>
                        <div class="error-top-item" id="zero-moving">
                            <img src="/assets/images/other/page-not-found/2.png" class="img-responsive" alt="404">
                        </div>
                        <div class="error-top-item">
                            <img src="/assets/images/other/page-not-found/1.png" class="img-responsive" alt="404">
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="error-bottom-wrapper">
                        <h2>Something is Wrong</h2>
                        <p>The page you are looking for was moved, removed, renamed or might never existed.</p>
                        <!-- <a  href="/" class="site-button site-button-secondry">Go Home</a> -->
                        <div class="text-center load-more-btn-outer"
                            style="background-image: url('/assets/images/background/cross-line.png');"><a
                               routerLink="/" href="/" class="site-button-secondry btn-half"><span>Go Home</span></a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>