import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-elements-box12',
  templateUrl: './elements-box12.component.html',
  styleUrls: ['./elements-box12.component.scss']
})
export class ElementsBox12Component {
  @Input() data: any;
  prevShareId:string = "";

  constructor() { }

  ngOnInit(): void {
  }

  shareInstagramPost(shareId:string) {
    document.getElementById("share-" + this.prevShareId)?.classList.remove("show");
    this.prevShareId = shareId;
    document.getElementById("share-" +shareId)?.classList.add("show");
  }
}
