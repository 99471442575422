<!-- <div class="sx-box image-hover-block">
    <div class="sx-thum-bx">
        <img src="{{data.thumbnail}}" alt="">
    </div>
    <div class="sx-info  p-t20 text-white">
        <h4 class="sx-tilte"><a routerLink="/projects/project-detail1">{{data.title}}</a></h4>
        <p class="m-b0">{{data.place}}</p>
    </div>
    <a class="mfp-link" href="{{data.image}}">
        <i class="fa fa-arrows-alt"></i>
    </a>
</div> -->
<div class="portfolios">
    <div class="portfolio">
        <div class="portfolio__inne">

            <div class="portfolio__content">
                <span class="portfolio-zoom-trigger sx-box image-hover-block">
                    <div class="sx-thum-bx">
                        <img src="{{data.thumbnail}}" alt="portfolio image">

                        <a class="mfp-link" href="{{data.image}}">
                            <i class="fa fa-arrows-alt"></i>
                        </a>
                    </div>
                </span>
                <div class="portfolio__content__text">
                    <!-- <div class="share__content__wrap">
                        <a class="share-toggle" href="javascript:void(0);"
                            (click)="shareInstagramPost(data.instagramId)"><span class="ti-sharethis"></span> share</a>
                        <div id="share-{{data.instagramId}}" class="share__content">
                            <a href="https://www.facebook.com/"><span class="fa fa-facebook"></span></a>
                            <a href="https://twitter.com/"><span class="fa fa-instagram"></span></a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>