<div class="project-mas {{shadow=='0' ? '' : 'hover-shadow'}} {{gap=='0' ? '' : 'm-a30'}}">
    <div class="image-effect-one">
      <img src="{{data.image}}" alt="" />
        <div class="figcaption">
            <a class="mfp-link" href="{{data.image}}">
                <i class="fa fa-arrows-alt"></i>
            </a>  
        </div>
    </div> 
    <!-- <div class="project-info p-a20 bg-gray">
        <h4 class="sx-tilte m-t0"><a routerLink="/projects/project-detail1">{{data.title}}</a></h4>
        <p>{{data.description}}</p>
        <a href="#"><i class="link-plus bg-primary"></i></a>
    </div> -->
</div>