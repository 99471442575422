import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    image: "/assets/images/common/hero-bg-2.webp",
    title: "Portfolio",
    description: "",
    page: "Portfolio"
  }

  footerData = {
    backExists: "0",
    wallpaper: ""
  }

  portfolios = [
    {
      instagramId: "CiucBR6vD67",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/1_t.webp",
      image: "assets/portfolio/1.webp"
    },
    {
      instagramId: "CixEkQ9v1Tb", 
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/2_t.webp",
      image: "assets/portfolio/2.webp"
    },
    {
      instagramId: "ChUVRk5vDNB",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/3_t.webp",
      image: "assets/portfolio/3.webp"
    },
    {
      instagramId: "ClOLf_qvBgr",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/4_t.webp",
      image: "assets/portfolio/4.webp"
    },
    {
      instagramId: "ClLjpiRPleX",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/5_t.webp",
      image: "assets/portfolio/5.webp"
    },
    {
      instagramId: "ClJNsfAve1v",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/6_t.webp",
      image: "assets/portfolio/6.webp"
    },
    {
      instagramId: "ClGaMTFPVgx",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/7_t.webp",
      image: "assets/portfolio/7.webp"
    },
    {
      instagramId: "ClDKLTevZyC",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/8_t.webp",
      image: "assets/portfolio/8.webp"
    },
    {
      instagramId: "ClAxkB9vbad",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/9_t.webp",
      image: "assets/portfolio/9.webp"
    },
    {
      instagramId: "Ck_Hp3XPYO2",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/10_t.webp",
      image: "assets/portfolio/10.webp"
    },
    {
      instagramId: "Ck5hfuWjKVT",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/11_t.webp",
      image: "assets/portfolio/11.webp"
    },
    {
      instagramId: "Ckz6PJMPGcM",
      category: "yoga",
      title: "",
      place: "",
      thumbnail: "assets/portfolio/12_t.webp",
      image: "assets/portfolio/12.webp"
    }
  ]
}
