<div class="sx-bnr-inr overlay-wraper bg-parallax bg-top-center"  data-stellar-background-ratio="0.5"  style="background-image:url({{data.image}});">
    <div class="overlay-main bg-black opacity-07"></div>
    <div class="container">
        <div class="sx-bnr-inr-entry">
            <div class="banner-title-outer">
                <div class="banner-title-name">
                    <h2 class="m-tb0">{{data.title}}</h2>                                
                    <!-- <p>
                        {{data.description}}
                    </p> -->
                </div>
            </div>
            <!-- BREADCRUMB ROW -->                            
            
                <div>
                    <ul class="sx-breadcrumb breadcrumb-style-2">
                        <li><a routerLink="/">Home</a></li>
                        <li>{{data.page}}</li>
                    </ul>
                </div>
            
            <!-- BREADCRUMB ROW END -->                        
        </div>
    </div>
</div>