<div class="page-wraper"> 
       	
    <!-- HEADER START -->
    <app-header4></app-header4>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-page-banner [data]="banner"></app-section-page-banner>
        <!-- INNER PAGE BANNER END -->
         
        <!-- ABOUT COMPANY START -->
        <app-section-about2 [data]='about'></app-section-about2>
        <!-- ABOUT COMPANY END -->
        
        <!-- WHAT WE DO START -->
        <app-section-what-we-do1 [data]='whatwedo'></app-section-what-we-do1>  
        <!-- WHAT WE DO END -->          

         <!-- OUR AWARDS SECTION START -->
         <app-section-awards1 [data]='awards'></app-section-awards1>  
        <!-- OUR AWARDS SECTION END -->  
         
         <!-- OUR TEAM START -->
         <app-section-team2 [data]='team'></app-section-team2>  
        <!-- OUR TEAM END --> 
        
         <!-- TESTIMONIAL START -->
         <app-section-testimonials2 [data]='testimonials'></app-section-testimonials2> 
        <!-- TESTIMONIAL  END -->                          
        
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-footer1 [data]='footerData'></app-footer1>
    <!-- FOOTER END -->
    
</div>