<div class="page-wraper"> 
       	
    <!-- HEADER START -->
    <app-header4></app-header4>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-page-banner [data]="banner"></app-section-page-banner>
        <!-- INNER PAGE BANNER END -->
         
        <!-- ABOUT COMPANY START -->
        <app-section-about3 [data]='about'></app-section-about3> 
        <!-- ABOUT COMPANY END -->
        
        <!-- WHAT WE DO START -->
        <app-section-what-we-do3 [data]='whatwedo'></app-section-what-we-do3> 
        <!-- WHAT WE DO END -->         
         
        <!-- COUNTER START -->
        <app-section-counter1 [data]='counter'></app-section-counter1>  
        <!-- COUNTER  END -->
        
         <!-- OUR TEAM START -->
         <app-section-team1 [data]='team'></app-section-team1>
        <!-- OUR Team END -->                         

        <!-- OUR CLIENTS -->
        <app-section-clients1 [data]='clients'></app-section-clients1> 
        <!-- OUR CLIENTS END --> 
        
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-footer1 [data]='footerData'></app-footer1>
    <!-- FOOTER END -->
    
</div>