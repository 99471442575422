import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoaderDirective } from './directives/loader.directive';
import { LoaderComponent } from './elements/loader/loader.component';
import { FooterComponent } from './sections/footer/footer.component';
import { HeaderComponent } from './sections/header/header.component';
import { PageAbout1Component } from './pages/about/page-about1/page-about1.component';
import { PageAbout2Component } from './pages/about/page-about2/page-about2.component';
import { PageProjectDetail1Component } from './pages/portfolio/detail/page-project-detail1/page-project-detail1.component';
import { PageProjectDetail2Component } from './pages/portfolio/detail/page-project-detail2/page-project-detail2.component';
import { PageHistoryComponent } from './pages/other/page-history/page-history.component';
import { PageFontIconsComponent } from './pages/other/page-font-icons/page-font-icons.component';
import { PageError404Component } from './pages/other/page-error404/page-error404.component';
import { PageFaqComponent } from './pages/other/page-faq/page-faq.component';
import { SectionSlider1Component } from './sections/home/section-slider/section-slider1.component';
import { SectionAbout1Component } from './sections/home/section-about/section-about1.component';
import { SectionWhatWeDo1Component } from './sections/home/section-what-we-do/section-what-we-do1.component';
import { SectionTestimonialsComponent } from './sections/home/section-testimonials/section-testimonials.component';
import { SectionServices1Component } from './sections/home/section-services/section-services1.component';

import { SectionPageBannerComponent } from './sections/section-page-banner/section-page-banner.component';
import { SafePipe } from './pipes/safe.pipe';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PortfolioModule } from './modules/portfolio/portfolio.module';
import { ContactModule } from './modules/contact/contact.module';
import { TermsComponent } from './pages/other/terms/terms.component';
import { PrivacyComponent } from './pages/other/privacy/privacy.component';
import { ElementsBox12Component } from './elements/boxes/elements-box12/elements-box12.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ElementsBox5Component } from './elements/boxes/elements-box5/elements-box5.component';
import { ElementsBox6Component } from './elements/boxes/elements-box6/elements-box6.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoaderDirective,
    LoaderComponent,
    FooterComponent,
    HeaderComponent,
    PageAbout1Component,
    PageAbout2Component,
    PortfolioComponent,
    ContactComponent,
    PageProjectDetail1Component,
    PageProjectDetail2Component,
    PageHistoryComponent,
    PageFontIconsComponent,
    PageError404Component,
    PageFaqComponent,
    SectionSlider1Component,
    SectionAbout1Component,
    SectionWhatWeDo1Component,
    SectionTestimonialsComponent,
    SectionServices1Component,
    SectionPageBannerComponent,
    SafePipe,
    TermsComponent,
    PrivacyComponent,
    ElementsBox5Component,
    ElementsBox6Component,
    ElementsBox12Component
  ],
  imports: [
    NgbModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    GoogleMapsModule,
    HttpClientModule,
    PortfolioModule,
    ContactModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
