import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  services = {
    title: "Why US?",
    title2: "Expertise",
    services: [
      {
        serial: "1",
        icon: "flaticon-sketch",
        title: "LIGHT COMPOSURE",
        description: "Light is a dynamic element that you have at your disposal when capturing a photograph; Composition is how you choose to combine various elements to tell a story."
      },
      {
        serial: "2",
        icon: "flaticon-stairs",
        title: "PROFESSIONAL SKILLS",
        description: "Captures moments on film and uses images to convey stories. They collaborate with art teams, develop concepts, and employ various techniques to achieve desired results."
      },
      {
        serial: "3",
        icon: "flaticon-window",
        title: "PERFECT EQUIPMENT",
        description: "Capturing the perfect photograph requires more than just a camera. A tripod to keep your camera steady. A reflector to bounce light back into your subject's face."
      },
      {
        serial: "4",
        icon: "flaticon-skyline",
        title: "ULTRA HD",
        description: "Our photographs easily exceed resolutions beyond 6,000 x 4,000 pixels at 300 dpi. This allows for extremely large high quality outdoor prints."
      },
      {
        serial: "5",
        icon: "flaticon-bed",
        title: "VISION",
        description: "Being able to think creatively and attentively at a subject and capture it in a way that conveys meaning. It is abstract and expressive."
      },
      {
        serial: "6",
        icon: "flaticon-door",
        title: "FOCUSING KNOWLEDGES",
        description: "We work best with the lens to highlight a person or a situation. Focus is one of the pillars of photography, along with ISO, aperture, and shutter speed."
      }
    ]
  }

}
