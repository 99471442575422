<!-- HEADER START -->
<app-header></app-header>
<!-- HEADER END -->

<router-outlet></router-outlet>

<!-- BUTTON TOP START -->
<button class="scroltop"><span class="fa fa-angle-up  relative" id="btn-vibrate"></span></button>

<!-- LOADING AREA ===== -->
<app-loader></app-loader>

<!-- FOOTER START -->
<app-footer [data]='footerData'></app-footer>
<!-- FOOTER END -->