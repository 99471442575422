import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortfolioComponent } from 'src/app/pages/portfolio/portfolio.component';

import { PageProjectDetail1Component } from '../../pages/portfolio/detail/page-project-detail1/page-project-detail1.component';
import { PageProjectDetail2Component } from '../../pages/portfolio/detail/page-project-detail2/page-project-detail2.component';

const routes: Routes = [
  { path: '', component: PortfolioComponent },
  { path: 'project-detail1', component: PageProjectDetail1Component },
  { path: 'project-detail2', component: PageProjectDetail2Component }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortfolioRoutingModule { }
